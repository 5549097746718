import React, { useState, useRef } from "react";
import S3 from "react-aws-s3";
import Spinner from "./spinner.gif";
import logo from "./logo-neww.png";
//import { MY_PROFILE } from "../../actions/types";

import { render } from "react-dom";
import FilerobotImageEditor from "filerobot-image-editor";
import "./styles.css";

const UploadImageToBucket = (props) => {
  //const [files, setFiles] = useState([]);
  console.log(FilerobotImageEditor);

  const [filesError, setFilesError] = useState("");
  const [uploadedImage, setUploadedImage] = useState("");
  //const fileInput = useRef();
  //const src = "https://scaleflex.airstore.io/demo/stephen-walker-unsplash.jpg";
  const [show, toggle] = useState(false);

  const isValidImage = (file) => {
    if (
      file.type === "image/jpg" ||
      file.type === "image/jpeg" ||
      file.type === "image/png" ||
      file.type === "image/svg" ||
      file.type === "image/gif"
    ) {
      return true;
    } else {
      return false;
    }
  };

  const isCorrect = (file, cb) => {
    const img = new Image();
    img.src = window.URL.createObjectURL(file);
    img.onload = () => {
      cb(true);
      //   if (img.height == props.imageHeight && img.width == props.imageWidth) {
      //     cb(true);
      //   } else {
      //     alert(
      //       `Image Dimensions must be ${props.imageWidth} x ${props.imageHeight}`
      //     );
      //     cb(false);
      //   }
    };
  };
  const Upload = () => {
    const fileInput = useRef();
    const imageChange = (e) => {
      e.preventDefault();
      setUploadedImage("");

      if (e.target.files.length <= 0) {
        setFilesError("Requried");
      } else if (isValidImage(e.target.files[0])) {
        const file = e.target.files[0];
        const newFileName = Date.now() + "-media";
        isCorrect(e.target.files[0], (data) => {
          if (data) {
            setUploadedImage(Spinner);

            //props.setFiles(file);
            setFilesError("");
            const config = {
              bucketName: process.env.REACT_APP_BUCKET_NAME,
              region: process.env.REACT_APP_REGION,
              accessKeyId: process.env.REACT_APP_ACCESS_KEY_ID,
              secretAccessKey: process.env.REACT_APP_SECRET_ACCESS_KEY,
            };
            const ReactS3Client = new S3(config);

            ReactS3Client.uploadFile(file, newFileName).then((data) => {
              setUploadedImage(data.location);
              console.log(data.location);

              if (data.status === 204) {
                console.log("berhasil diupload");
              } else {
                console.log("gagal");
              }
            });
          } else {
            setFilesError("Invalid Dimensions");
          }
        });
      }
    };
    return (
      <>
        <div className="main_header">
          <div className="main_logo_img">
            <a href="https://imagespunjab.com/">
              <img className="width_100" src={logo} />
            </a>
          </div>
        </div>

        <div className="bodrder_area">
          <div className="text_cntr">
            <h2>
              <strong>To edit your photo, upload it here</strong>
            </h2>
          </div>

          <div className="main-wrpper">
            <div className="file-input">
              <input
                type="file"
                name="file-input"
                id="file-input"
                className="file-input__input"
                onChange={imageChange}
                accept="image/*"
                ref={fileInput}
              />
              <label className="file-input__label" htmlFor="file-input">
                <svg
                  aria-hidden="true"
                  focusable="false"
                  data-prefix="fas"
                  data-icon="upload"
                  className="svg-inline--fa fa-upload fa-w-16"
                  role="img"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 512 512"
                >
                  <path
                    fill="currentColor"
                    d="M296 384h-80c-13.3 0-24-10.7-24-24V192h-87.7c-17.8 0-26.7-21.5-14.1-34.1L242.3 5.7c7.5-7.5 19.8-7.5 27.3 0l152.2 152.2c12.6 12.6 3.7 34.1-14.1 34.1H320v168c0 13.3-10.7 24-24 24zm216-8v112c0 13.3-10.7 24-24 24H24c-13.3 0-24-10.7-24-24V376c0-13.3 10.7-24 24-24h136v8c0 30.9 25.1 56 56 56h80c30.9 0 56-25.1 56-56v-8h136c13.3 0 24 10.7 24 24zm-124 88c0-11-9-20-20-20s-20 9-20 20 9 20 20 20 20-9 20-20zm64 0c0-11-9-20-20-20s-20 9-20 20 9 20 20 20 20-9 20-20z"
                  />
                </svg>
                <span>Upload file</span>
              </label>
            </div>
          </div>
        </div>

        {/* <input
          accept="image/*"
          ref={fileInput}
          placeholder="User Profile"
          onChange={imageChange}
          type="file"
          className="w-100"
          //onChange={onChangePicture}
        /> */}
        {uploadedImage != "" && uploadedImage == Spinner && (
          <div className="image_main_contnr">
            <img src={uploadedImage} className="uploaded-blog-image mt-2" />
          </div>
        )}
        {filesError !== "" ? (
          <p className="error-message">{filesError}</p>
        ) : null}
      </>
    );
  };
  return (
    <>
      <Upload />
      {uploadedImage != "" && uploadedImage != Spinner && (
        <>
          <div className="image_main_contnr">
            <img
              src={uploadedImage}
              onClick={() => {
                toggle(true);
              }}
              alt="example"
            />
          </div>
          <div className="modfctn_img">
            <p>Click on image to Edit / Modify it</p>
          </div>
        </>
      )}
      <style
        dangerouslySetInnerHTML={{
          __html:
            "\n.sc-AxirZ:nth-child(9) {\n    display: none !important;\n}\n",
        }}
      />
      <FilerobotImageEditor
        show={show}
        src={uploadedImage}
        onClose={() => {
          toggle(false);
        }}
      />

      <div className="hdr__bck">
        <aside className="responsive-banner first">
          <div className="container-envelope">
            <svg className="cirle-a" height="160" width="160">
              <circle cx="80" cy="80" r="80"></circle>
            </svg>
            <svg className="cirle-b" height="60" width="60">
              <circle cx="30" cy="30" r="30"></circle>
            </svg>
            <svg className="cirle-d" height="60" width="60">
              <circle cx="30" cy="30" r="30"></circle>
            </svg>
            <div className="col-xs-12 main__area_image">
              <p>
                Experience the enchanting beauty of Punjab with our exceptional
                stock photos, handpicked to inspire and delight. Our images
                offer a glimpse into the rich culture & picturesque landscapes
                of this region.
              </p>
              <a
                target="_blank"
                href="https://imagespunjab.com/"
                className="explore_more-link"
              >
                Explore
              </a>
            </div>
          </div>
        </aside>
      </div>
      <div className="footer_main">
        <p>
          © Copyright 2023 By{" "}
          <a href="https://www.frescowebservices.com/">Fresco Web Services</a>
        </p>
      </div>
    </>
  );
};
render(<UploadImageToBucket />, document.getElementById("root"));
